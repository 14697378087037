import React from 'react'
import * as Icons from 'react-feather'
import SVGIcon from './SVGIcon'

// export type IconName = keyof typeof icons;

const Icon = ({ name, ...rest }) => {
  if (name.includes('_')) {
    switch (name) {
      case '_recycleIcon': {
        return <SVGIcon src="/images/recycle.svg" />
      }
      case '_fiveStarIcon': {
        return (
          <SVGIcon
            outerClassName="SVGIconR"
            innerClassName="SVGIconR--icon"
            src="/images/fivestars.svg"
          />
        )
      }
      case '_lowPriceIcon': {
        return (
          <SVGIcon
            outerClassName="SVGIconR"
            innerClassName="SVGIconR--icon"
            src="/images/lowprice.svg"
          />
        )
      }
      case '_buildingIcon': {
        return (
          <SVGIcon
            outerClassName="SVGIconR"
            innerClassName="SVGIconR--icon"
            src="/images/building.svg"
          />
        )
      }
      case '_creditCard': {
        return (
          <SVGIcon
            outerClassName="SVGIconR"
            innerClassName="SVGIconR--icon"
            src="/images/creditcard.svg"
          />
        )
      }
      case '_skipsIcon': {
        return (
          <SVGIcon
            outerClassName="SVGIconRB"
            innerClassName="SVGIconR--icon"
            src="/images/skips.svg"
          />
        )
      }

      default: {
        return null
      }
    }
  }
  const IconComponent = Icons[name]
  return (
    <IconComponent
      style={{ height: 64, width: 64, fontSize: '1em', color: 'black' }}
      {...rest}
    />
  )
}

export default Icon
