/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import { SimpleGrid } from '@mantine/core'

import PageHeader from '../components/PageHeader'
// import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import HomePageBullet from '../components/HomePageBullet'

// Export Template for use in CMS preview
const HomePageTemplate = ({ data }) => {
  const { title, subTitle, featuredImage, homePageBullets } =
    data.directus.homePage

  const st = homePageBullets.map((i) => <HomePageBullet data={i} />)

  return (
    <main>
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      {/* <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
      </section> */}
      <section className="section">
        <div className="container">
          <SimpleGrid
            spacing="lg"
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: 'md' },
              { maxWidth: 755, cols: 1, spacing: 'sm' },
              { maxWidth: 600, cols: 1, spacing: 'sm' }
            ]}
            cols={4}
          >
            {st}
          </SimpleGrid>
        </div>
      </section>
    </main>
  )
}

const HomePage = ({ data }) => (
  <Layout meta={data.directus.homePage.SEO || false}>
    <HomePageTemplate data={data} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query homePage {
    directus {
      homePage {
        homePageBullets {
          description
          icon
        }
        title
        subTitle
        featuredImage {
          id
        }
        body
      }
    }
  }
`
