import React from 'react'

import { Title, Flex } from '@mantine/core'
import Icon from './Icon'

const HomePageBullet = ({ data }) => {
  return (
    <div className="note">
      <Flex justify="center" sx={{ marginBottom: 8 }}>
        <Icon name={data.icon} />
      </Flex>
      <Title align="center" order={3}>
        {data.description}
      </Title>
    </div>
  )
}

export default HomePageBullet
