import React from 'react'

import './SVGIcon.css'

export default ({ src, outerClassName, innerClassName }) => {
  const icon = {
    maskImage: `url(${src})`,
    WebkitMaskImage: `url(${src})`
  }
  return (
    <div className={outerClassName || 'SVGIcon'}>
      <div className={innerClassName || 'SVGIcon--icon'} style={icon} />
    </div>
  )
}
